import './App.css';
import { Box, Button } from "@mui/material";
import { useEffect } from "react";

const TelegramWebApp = window.Telegram.WebApp;

function App() {

  useEffect(() => {
    TelegramWebApp.ready();
  });

  return (
    <Box>
      <Button
        variant={'contained'}>
        Here
      </Button>
    </Box>
  );
}

export default App;
